import React, { useState, useEffect } from "react"
import Img from "gatsby-image"
import { Link, navigate } from "gatsby"
import { useMediaQuery } from "react-responsive"
import Layout from "../components/layout"
import styles from "../styles/places.module.css"
import BackgroundImage from "gatsby-background-image"
import Arrow from "../images/logos/arrow.svg"
import phoneLogo from "../images/logos/phoneLogo.svg"
import adressLogo from "../images/logos/adressLogo.svg"
import PlacesMobile from "../components/placesMobile"
import BackPageArrow from "../images/backpagearrow.svg"

const Places = ({ data }) => {
  const mobile = useMediaQuery({ query: "(max-width: 485px)" })
  const [active, setActive] = useState(false)
  
  useEffect(() => {
    let local = localStorage.getItem("age")
    let session = sessionStorage.getItem("age")
    // console.log(local,session)
    if (!session && !local) navigate("/agegate",{state: {newPath : "/places"}})
  },[]) 

  const cardData = data.allPlaces.nodes.sort(function (a, b) {
    return a.order - b.order
  })
  const placesEdges = data.allPlaces.edges.sort(function (a, b) {
    return a.node.order - b.node.order
  })

  useEffect(() => {
    window.dataLayer.push({
      brand: "Quilmes",
      Zone: "SAZ",
      country: "ARG",
      city: "CABA",
      campaign: `${
        window.location.search.includes("?cmp=")
          ? window.location.search.split("?cmp=")[1].split("&")[0]
          : ""
      }`,
      cms: "0",
      event: "pageView",
      language: "es",
      login: false,
      pageName: "Nuestros Lugares",
      pageType: "Basic page",
      SiteType: "Brand",
      product: "NA",
      sku: "NA",
      userUid: "",
      url: "https://www.quilmes.com.ar/places",
      url_campaign: `${
        window.location.search.includes("utm_campaign=")
          ? window.location.search.split("utm_campaign=")[1]
          : ""
      }`,
      Step_number: "",
    })
  }, [])


  return (
    <>
      {!mobile ? (
        <Layout>
          <section className={styles.background}>
            <BackgroundImage
              fluid={data.bocaAncha.childImageSharp.fluid}
              className={styles.ourPlacesBack}
              alt="imágen de botella de cerveza boca ancha"
            >
              <div className={styles.backPage}>
                <img alt="flecha para volver" src={BackPageArrow} />
                <div className={styles.backPage}>
                  <img
                    alt="flecha para volver"
                    src={BackPageArrow}
                    id={active ? styles.backArrow : styles.backArrowHidden}
                  />
                  <Link
                    onMouseLeave={() => setActive(!active)}
                    onMouseOver={() => setActive(!active)}
                    to={"/#places"}
                    style={{
                      textDecoration: "none",
                      color: "white",
                      backgroundColor: "transparent",
                      border: "none",
                      outline: "inherit",
                    }}
                  >
                    VOLVER
                  </Link>
                </div>
              </div>
              <div
                className={`${styles.textContainer} ${styles.slideTextLeft}`}
              >
                <h1>VISÍTANOS EN</h1>
                <h2>NUESTROS LUGARES</h2>
                <div id={styles.lineaBlue}>
                  <hr></hr>
                </div>
                <p style={{ color: "white" }}>
                  Además de la Quilmes que tomás en tu casa queremos que
                  disfrutes la que servimos en la nuestra. Ya sea en El Parque
                  o en Los Clásicos, servimos la Quilmes más fresca porque
                  llega directamente de la cervecería. Pasen y siéntanse
                  cómodos
                </p>
                <div id={styles.blueDot}></div>
                <div id={styles.arrowCircle}>
                  <div id={styles.circleFixed}>
                    <img
                      alt="flecha para bajar"
                      id={styles.arrowTop}
                      src={Arrow}
                    />
                  </div>
                </div>
              </div>
            </BackgroundImage>

            <div
              className={`${styles.cardsContainer} ${styles.slideColText}`}
            >
              <div className={styles.cardsTitle}>
                <h1>DIRECTA DE CERVECERÍA</h1>
                <h2>Encontrá la cerveza más fresca del país</h2>
              </div>
              <div className={styles.cards}>
                {cardData.map(info => {
                  return (
                    <div
                      key={info.name}
                      className={`card ${styles.card}`}
                      style={{ width: "18rem" }}
                    >
                      <Img
                        alt="imágen de cervecería"
                        style={{
                          borderTopLeftRadius: "15px",
                          borderTopRightRadius: "15px",
                        }}
                        fluid={
                          placesEdges[info.order].node.localImage
                            .childImageSharp.fluid
                        }
                      ></Img>
                      <div className="card-body">
                        <h5 className={`card-title ${styles.cardTitle}`}>
                          {info.name.toUpperCase()}
                        </h5>
                        <div className={styles.adress}>
                          <img alt="logo de dirección" src={adressLogo} />
                          <h6 className={styles.phoneAdress}>
                            {info.adress}
                          </h6>
                        </div>
                        <div className={styles.phone}>
                          <img alt="logo de teléfono" src={phoneLogo} />
                          <h6 className={styles.phoneAdress}>{info.phone}</h6>
                        </div>

                        <p className={`card-text ${styles.cardText}`}>
                          {info.schedule1}
                        </p>
                        <p className={`card-text ${styles.cardText}`}>
                          {info.schedule2}
                        </p>
                        <p className={`card-text ${styles.cardText}`}>
                          {info.schedule3}
                        </p>
                        <p className={`card-text ${styles.cardText}`}>
                          {info.schedule4}
                        </p>
                        <h6 className={styles.info}>{info.info}</h6>
                        <a className={styles.link} href="#" target="_blank">
                          VER EN MAPA
                        </a>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </section>
        </Layout>
      ) : (
        <PlacesMobile data={data}></PlacesMobile>
      )}
    </>
  )
}

export default Places

export const query = graphql`
  query {
    bocaAncha: file(relativePath: { eq: "chopsQuilmes.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    birraMobile: file(relativePath: { eq: "birraMobile.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allPlaces {
      nodes {
        adress
        cardImg
        map
        name
        order
        phone
        schedule1
        schedule2
        schedule3
        schedule4
        info
        id
      }
    }
    allPlaces {
      edges {
        node {
          order
          localImage {
            childImageSharp {
              fluid(maxWidth: 1800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
