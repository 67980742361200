import React, { useState } from "react"
import { useMediaQuery } from "react-responsive"
import Layout from "../components/layout"
import styles from "../styles/placesMobile.module.css"
import BackgroundImage from "gatsby-background-image"
import cardArrow from "../images/logos/cardArrow.svg"

import phoneLogo from "../images/logos/phoneLogo.svg"
import adressLogo from "../images/logos/adressLogo.svg"

const CardMobile = ({ info }) => {
  const [active, setActive] = useState(false)
  return (
    <>
      <div className={styles.responsiveCard}>
        <img id={styles.cardImg} src={info.cardImg} />
        {!active ? (
          <div className={styles.cardBody}>
            <div>
              <h5 className={`card-title ${styles.cardTitle}`}>
                {info.name.toUpperCase()}
              </h5>
              <div className={styles.adress}>
                <img src={adressLogo} />
                <h6 className={styles.phoneAdress}>{info.adress}</h6>
              </div>
              <div className={styles.phone}>
                <img src={phoneLogo} />
                <h6 className={styles.phoneAdress}>{info.phone}</h6>
              </div>
            </div>
            <img
              id={styles.cardArrow}
              src={cardArrow}
              onClick={() => {
                setActive(!active)
              }}
            />
          </div>
        ) : (
          <div className={styles.cardBody}>
            <p className={`card-text ${styles.cardText}`}>{info.schedule1}</p>
            <p className={`card-text ${styles.cardText}`}>{info.schedule2}</p>
            <p className={`card-text ${styles.cardText}`}>{info.schedule3}</p>
            <p className={`card-text ${styles.cardText}`}>{info.schedule4}</p>
            <img
              id={styles.cardArrow}
              onClick={() => {
                setActive(!active)
              }}
              src={cardArrow}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default CardMobile
