import React from "react"
// import { Link } from "react-scroll"
// import { useMediaQuery } from "react-responsive"
import Layout from "../components/layout"
import styles from "../styles/placesMobile.module.css"
import BackgroundImage from "gatsby-background-image"
import CardMobile from "../components/cardMobile"
// import Slider from "react-slick"
// import barra from "../images/barra.png"
// import elClasicoPuerta from "../images/elclasicopuerta.png"
// import quilmesAdrogué from "../images/quilmesAdrogué.png"

// let dataImg = [
//   {
//     img: barra,
//     alt: "Barra de bar",
//   },
//   {
//     img: elClasicoPuerta,
//     alt: "Puerta de El Clásico de Quilmes",
//   },
//   {
//     img: quilmesAdrogué,
//     alt: "Gente disfrutando en el Clásico de Quilmes Adrogué",
//   },
// ]

// const settings = {
//   dots: false,
//   infinite: false,
//   speed: 500,
//   slidesToShow: 1,
//   slidesToScroll: 1,
//   arrows: false,
// }

const PlacesMobile = ({ data }) => {
  // const mobile = useMediaQuery({ query: "(max-width: 485px)" })
  const cardData = data.allPlaces.nodes
  /*   const cardData = data.allPlaces.nodes.sort(function (a, b) {
    return a.order - b.order
  }) */
  return (
    <>
      <Layout>
        <section className={styles.sectionOne}>
          <BackgroundImage
            fluid={data.birraMobile.childImageSharp.fluid}
            className={styles.backgroundMobile}
          >
            <div className={styles.textContainer}>
              <h1>VISÍTANOS EN</h1>
              <h2>NUESTROS LUGARES</h2>
              <div id={styles.lineaWhite}>
                <hr></hr>
              </div>
              <h3>Encontrá la cerveza más fresca del país.</h3>
            </div>
          </BackgroundImage>
        </section>
        <section className={styles.sectionTwo}>
          {/* <div className={styles.buttons}>
            <button
              aria-pressed="true"
              className={`btn ${styles.filterButton}`}
            >
              VER TODOS
            </button>
            <button aria-pressed="true" className="btn">
              CABA
            </button>
            <button aria-pressed="true" className="btn">
              GBA
            </button>
            <button aria-pressed="true" className="btn">
              LA COSTA
            </button>
          </div> */}
          {cardData.map(info => {
            return (
              <CardMobile key={info.name} data={data} info={info}></CardMobile>
            )
          })}
        </section>
        {/* <section className={styles.sectionThree}>
          <div className={styles.sectionThreeText}>
            <h1>LOS CLÁSICOS DE QUILMES</h1>
            <div id={styles.lineaBlue}>
              <hr></hr>
            </div>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Exceptur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
              Excepteur sint occaecat cupidatat.
            </p>
            <p>
              {" "}
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>
          </div>
          <div className={styles.sliderContainer}>
            <Slider {...settings}>
              {dataImg.map(imagen => {
                return <img src={imagen.img} alt={imagen.alt} />
              })}
            </Slider>
          </div>
        </section> */}
      </Layout>
    </>
  )
}

export default PlacesMobile
